<template>
  <v-col cols="12" sm="12">

    <v-toolbar class="" elevation="0" color="transparent">
      <v-toolbar-title style="font-size:1.8em">
        <v-icon>mdi-restore-alert</v-icon> 
        Recover from blockchain 
        <!-- ({{ reportList.length }}) -->
      </v-toolbar-title>
      <v-btn outlined icon :loading="loading" @click="fetchData()" class="mx-2">
        <v-icon>mdi-refresh</v-icon>
      </v-btn>
      <v-spacer/>
      <v-btn outlined  v-if="nodeEnv == 'development'" color="red"
            :loading="loading" @click="recover()" class="mx-2" title="recover from blockchain">
        <v-icon>mdi-restore-alert</v-icon> Start recover
      </v-btn>
    </v-toolbar>

    <v-toolbar dense class="mb-5" elevation="0" color="transparent">

      <v-spacer/>
      <template v-for="(sName, s) in streamNamesList2">
        <v-btn :outlined="streamName != sName" :key="s"
                :color="streamName == sName ? 'teal' : ''" 
                small @click="loadData(sName)" class="mx-2">
          <v-icon small class="mr-1">mdi-link-lock</v-icon> {{ sName }}
        </v-btn>
      </template>

      <v-spacer/>
    </v-toolbar>

    <v-row class="row-bg-white-t">
      <v-col cols="10" sm="11" md="12">
        <v-row style="" class="py-3 px-3">
          <template v-for="(wallet, w) in wallets">
            <v-col cols="12" sm="6" lg="4" class="pa-1" :key="w" v-if="w < 30">
              <v-card class="pa-2" elevation="0" outlined>
                <b v-if="wallet.owner != null">{{ wallet.owner.name }}</b> | {{ wallet.uid }} | <b>
                  {{ convertUnityMony(wallet.amountUnity).toFixed(6) }}</b> {{ config.monyUnity }}<br>
                <b>{{ wallet.amountUnity }}</b> ù
              </v-card>
            </v-col>
          </template>
        </v-row>
      </v-col>
    </v-row>

  </v-col>
</template>

<style></style>

<script>
import axios from 'axios'
const config = require('../../config/' + process.env.NODE_ENV)
//import router from '../router/router'
import core from '../../plugins/core.js'

// import DashboardHeader from '@/components/admin/DashboardHeader.vue'
// import TransactionBoard from '@/components/admin/transaction/TransactionBoard.vue'
// import BlockStatVisitors from '@/components/admin/BlockStatVisitors.vue'
// import BlockStatSystem from '@/components/admin/BlockStatSystem.vue'
import Gravatar from 'vue-gravatar'

export default {
  name: 'admin-dashboard',
  components: { 'v-gravatar' : Gravatar, 
                 //TransactionBoard, BlockStatVisitors, BlockStatSystem 
              },
  data: () => ({
    loading: false,
    nodeEnv: process.env.NODE_ENV,
    wallets: []
  }),
  async mounted(){
    //this.nodeEnv = config.env
    this.fetchWallets()
    //this.loadAdmins()
  },
  methods: {
    async fetchWallets(){
      await this.$store.dispatch('app/fetchEntities', { entityType: "walletMain", limit: 30, sort: { created: 1 } })
      this.wallets = this.$store.state.app.data.walletMain
    },
    async recover(){
      let res = await axios.post('/admin/restore-from-blockchain')
      if(res.data.error == false){
        console.log("RESTORE OK", res.data)
        this.fetchWallets()
        this.$store.dispatch('app/openSnackbar',  { show: true, color:'green', 
                                                    title: "Fin de la restauration des données",
                                                    text: "" })
      }else{
        console.log("RESTORE ERROR", res.data)
      }
    },
    convertUnityMony(amountUnity){
      return core.convertUnityMony(amountUnity, this.$store.state.auth.monyConvertValue)
    },
    convertMonyUnity(amountMony){
      return core.convertMonyUnity(amountMony, this.$store.state.auth.monyConvertValue)
    }
  },
  computed: {
    config(){ return config }
    //nodeEnv(){ return process.env.NODE_ENV }
  },
  filters: {
    
  },
  watch: {
     
  }, 
}
</script>
